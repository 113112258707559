.project {
  max-width: 320px;
  background-color: white;
  box-shadow: 1px 1px 10px 1px #a5a5a575;

  .img-description-box {
    width: 100%;

    img {
      width: 100%;
    }

    .description {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 2em;
      opacity: 0;
      background-color: #f9f6f6;
      transition: opacity 0.1s;
      line-height: 1.5em;

      // &:hover {
      //   opacity: 0.97;
      // }
    }
  }

  .text {
    padding: 20px;

    .name {
      font-size: 1.2em;
    }

    .tech {
      display: inline-block;
      font-size: 14px;
      margin-top: 10px;
    }

    .icon-wrapper {
      margin-top: 10px;

      & > * {
        margin-right: 8px;
        font-size: 1.2em;
        height: 1.4em;
        width: 1.4em;

        & > * {
          margin-top: 4px;
        }
      }
    }
  }
}
