@import "../variables";

.side-nav {
  position: fixed;
  left: 0;
  list-style-type: none;
  margin: 0;
  margin-left: 40px;

  // center vertically
  top: 50vh;
  transform: translateY(-50%);

  z-index: 999;

  li {
    margin: 30px 0;

    * {
      display: inline-block;
      vertical-align: middle;
      transition: 0.3s;
    }

    .item-dot {
      height: 10px;
      width: 10px;
      background-color: $sidenav-colour;
      border-radius: 50%;

      &::before {
        content: " ";
        height: 10px;
        width: 10px;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        background-color: $sidenav-colour-halo;
        transition: 0.3s;
        z-index: -1;
      }
    }

    &.active {
      .item-dot {
        background-color: $sidenav-colour-active;

        &::before {
          transform: scale(2);
        }
      }
    }

    .dot-wrapper {
      padding: 0 5px;

      &:hover {
        cursor: pointer;
        transform: scale(1.15);
      }
    }

    h3 {
      opacity: 0;
      pointer-events: none;
      color: #575757;
      margin-left: 35px;
      font-family: "Poppins", sans-serif;
    }
  }

  &.home.active h3,
  li .dot-wrapper:hover + h3 {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
    transform: translateX(-10px);
  }
}
