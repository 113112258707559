$icon-colour: #977184;
$icon-highlight-colour: #977184;
$navbar-bg-colour: white;
$navbar-bg-colour-away: #f7f1f1;
$sidenav-colour: #f7b5b5;
$sidenav-colour-active: #8f0e4f;
$sidenav-colour-halo: #69696940;

// $icon-colour: #666666;
// $icon-highlight-colour: #977184;
// $navbar-bg-colour: white;
// $navbar-bg-colour-away: #faf5f5;
// $sidenav-colour: #e2afaf;
// $sidenav-colour-active: #8f0e4f;
// $sidenav-colour-halo: #69696940;
