.landing-section {
  display: flex;
  align-items: center;
  justify-content: center;

  .name {
    font-weight: 400;
    font-size: 5vw;
    letter-spacing: 0.1em;
    font-family: "Epilogue", sans-serif;
    color: #383838;
  }

  @media screen and (max-width: 500px) {
    .name {
      font-size: 12vw;
    }
  }
}
