* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;

  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0.05em;

  color: #111111;

  scroll-behavior: smooth;
}
