@import "../variables";

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;

  padding: 2vh 2.5vh;

  background-color: $navbar-bg-colour-away;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 0.2s;

  &.home {
    background-color: $navbar-bg-colour;
  }

  & > div {
    display: inline-block;
  }

  a {
    font-size: 1.8em;
    height: 1.4em;
    width: 1.4em;
    margin-left: 8px;

    & > * {
      margin-top: 0.2em;
    }

    * {
      color: $icon-colour;
    }
  }

  .left {
    display: flex;
    align-items: center;

    span:hover {
      cursor: pointer;
    }

    svg {
      height: 28px;
      width: auto;
    }
  }
}

// @media only screen and (max-width: 750px), only screen and (max-height: 500px) {
//   .page.nav {
//     display: none;
//   }

//   .navbar {
//     padding: 4vmin 5vmin;
//   }
// }
