.about-section {
  .tech-stack {
    margin-left: 30px;
    list-style-type: circle;

    li {
      margin: 5px 0;
    }
  }
}
