.App {
  html,
  body {
    height: 100%;
  }

  canvas[resize] {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
