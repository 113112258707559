@import "./variables";

.App {
  p {
    font-size: 17px;
    margin: 20px 0;
    line-height: 1.5em;
  }

  a {
    display: inline-block;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: #7a7a7a;
    }
  }

  .icon-link {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    border-radius: 30%;
    transition: color, background-color 0.1s;
    color: $icon-colour;

    &:hover {
      background-color: $icon-highlight-colour;

      * {
        color: white;
      }
    }

    &::after {
      content: none;
    }
  }

  // button {
  //   font-size: 18px;
  //   padding: 8px 10px;
  //   border: 1px solid #9c9c9c;
  //   background-color: #ffffff88;
  //   transition: 0.15s;

  //   &:hover {
  //     color: #4d051d;
  //     border: 1px solid #b6446a;
  //     background-color: #f7dce46b;
  //     cursor: pointer;

  //     & > * {
  //       color: #4d051d;
  //     }
  //   }
  // }
}
