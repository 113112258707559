.section {
  padding: 6em 0;
  text-align: center;

  &.landing-section {
    height: 100vh;
  }

  .section-title {
    width: fit-content;
    margin-bottom: 50px;
    font-size: 24px;
    border-bottom: 1px solid #aaaaaa;
    font-family: "Poppins", sans-serif;
  }

  .content-wrapper {
    display: inline-block;
    width: 65%;
  }

  * {
    text-align: left;
  }
}

@media screen and (max-width: 850px) {
  .section .content-wrapper {
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .section .content-wrapper {
    width: 80%;
  }
}
