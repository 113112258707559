.project-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 30px;
  row-gap: 50px;
  justify-items: center;

  width: 65%;
}

@media screen and (max-width: 850px) {
  .project-container {
    width: 85%;
  }
}
